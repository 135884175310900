* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.imageContact {
  width: 70%;
  border-radius: 0.2rem;
  margin-top: 1rem;
}




