* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: url("../../assets/background.jpg");
}

.container {
  width: 70%;
  margin: 0 auto;
}

.content {
  background-color: #262626;
  color: white;
  padding: 1rem;
  border-radius: 0.25rem;
}

.image {
  width: 30%;
  border-radius: 0.2rem;
}

.title {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
}

.text {
  font-size: 1.2rem;
}

.mobile__container {
  width: 90%;
  margin: 0 auto;
}

.mobile__image {
  width: 100%;
  border-radius: 0.2rem;
}




