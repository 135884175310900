.contactUs {
 display: flex;
}

.bold {
    font-weight: bold;
}

.quote {
    width: 50rem;
}

.quoteText {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
}

.quoteAuthor {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
}

.mobile__slider {

    &__image { 
        width: 100%;
        margin-bottom: 0.2rem;
    }
    
}

.mobile__quote {
    width: 90%;
}




